import UIkit from "uikit";
import AutoLinker from "autolinker";
import deepEqual from "deep-equal";
import { DOCUMENT_TYPES, PDF_MIMETYPE } from "./constants";

// uikit

export function notify(message) {
  UIkit.notification({
    message,
    status: "primary",
    pos: "bottom-left",
    timeout: 5000,
  });
}

export function confirm(message) {
  return UIkit.modal
    .confirm(`<strong>${message}</strong>`, { stack: true })
    .then(() => true)
    .catch(() => false);
}

export function linkify(text) {
  return AutoLinker.link(text, { className: "uk-text-break" });
}

// data

export function selectAuth(user, admin = false) {
  return {
    uid: user.uid,
    name: user.displayName || user.uid,
    email: user.email || `${user.uid}@ezzyfeedback.com`,
    photo:
      user.photoURL ||
      `https://avatars.dicebear.com/api/avataaars/${user.uid}.svg`,
    emailVerified: user.emailVerified,
    admin,
    ezzyfeedback: true,
  };
}

export function sort(data, key) {
  return data.sort((a, b) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  });
}

// algorithms

export function uuid() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

// files

export const checkImage = (mimeType) => mimeType.startsWith("image/");

export const checkVideo = (mimeType) => mimeType.startsWith("video/");

export const checkAudio = (mimeType) => mimeType.startsWith("audio/");

export const checkPDF = (mimeType) => mimeType === PDF_MIMETYPE;

export const checkDocument = (mimeType) => DOCUMENT_TYPES.includes(mimeType);

export const checkAudioVideo = (attachment) =>
  Boolean(
    attachment &&
      (checkAudio(attachment.mimeType) || checkVideo(attachment.mimeType))
  );

export function attachmentType(mimeType) {
  if (checkImage(mimeType)) return "image";
  if (checkVideo(mimeType)) return "video";
  if (checkAudio(mimeType)) return "audio";
  if (checkDocument(mimeType)) return "document";
  return "";
}

export const webViewerURL = (url) =>
  `https://docs.google.com/viewer?url=${encodeURIComponent(url)}&embedded=true`;

// forms

export function getLabel(label, required) {
  return `${label} ${required ? "*" : ""}`;
}

export function selectValue({ label, value }) {
  return { label, value };
}

export function checkChanges(prev, curr) {
  return !deepEqual(prev, curr);
}

export function validateNumber(number, min, max) {
  return (
    typeof number === "number" &&
    Number.isInteger(number) &&
    number >= min &&
    number <= max
  );
}

export function validateString(string, min, max) {
  return (
    typeof string === "string" && string.length >= min && string.length <= max
  );
}

export function validateMultiSelect(array, min, max) {
  return Array.isArray(array) && array.length >= min && array.length <= max;
}

export function validateSingleSelect(obj, options) {
  return (
    obj && options.some((o) => o.label === obj.label && o.value === obj.value)
  );
}

export function validateRadio(string, options) {
  return typeof string === "string" && options.includes(string);
}

export function validateURL(str) {
  const pattern = new RegExp(
    // "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return pattern.test(str);
}
