import { useRef, useState, useEffect } from "react";
import UIkit from "uikit";

export default function useModal() {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const open = () => setIsOpen(true);

  const close = () => setIsOpen(false);

  useEffect(() => {
    if (isOpen) UIkit.modal(ref.current).show();
    else UIkit.modal(ref.current).hide();
  }, [isOpen]);

  return [ref, isOpen, open, close];
}
