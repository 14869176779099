import React from "react";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";
import { getLabel, selectValue } from "../../lib/utils";

function CreateSelect({
  label,
  field,
  value,
  options,
  handleChange,
  minOptions,
  maxOptions,
  placeholder,
  required,
}) {
  return (
    <div>
      <label className="uk-form-label">{getLabel(label, required)}</label>
      <div className="uk-form-controls">
        <CreatableSelect
          isMulti
          value={value}
          onChange={(value) =>
            handleChange(field, value ? value.map(selectValue) : [])
          }
          options={value.length === maxOptions ? [] : options}
          noOptionsMessage={() =>
            value.length === maxOptions
              ? "Maximum options selected"
              : "No options available"
          }
          placeholder={placeholder}
          className="react-select-container"
          classNamePrefix="react-select"
          isClearable
        />
        <div className="uk-text-meta">
          {minOptions}-{maxOptions} options
        </div>
      </div>
    </div>
  );
}

CreateSelect.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  minOptions: PropTypes.number.isRequired,
  maxOptions: PropTypes.number.isRequired,
  required: PropTypes.bool,
};

export default CreateSelect;
