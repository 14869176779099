import GOOGLE from "./google";

export default function usePicker(accessToken, callback) {
  function open() {
    if (accessToken) {
      const picker = new window.google.picker.PickerBuilder()
        .addView(window.google.picker.ViewId.DOCS)
        .addView(window.google.picker.ViewId.PDFS)
        .addView(window.google.picker.ViewId.DOCUMENTS)
        .addView(window.google.picker.ViewId.SPREADSHEETS)
        .addView(window.google.picker.ViewId.PRESENTATIONS)
        .addView(window.google.picker.ViewId.DOCS_IMAGES)
        .addView(window.google.picker.ViewId.DOCS_VIDEOS)
        .addView(window.google.picker.ViewId.DRAWINGS)
        .setTitle("Select files")
        .enableFeature(window.google.picker.Feature.SIMPLE_UPLOAD_ENABLED)
        .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
        .enableFeature(window.google.picker.Feature.MINE_ONLY)
        .setAppId(GOOGLE.APP_ID)
        .setOAuthToken(accessToken)
        .setDeveloperKey(GOOGLE.API_KEY)
        .setMaxItems(1)
        .setCallback((data) => {
          if (data.action === window.google.picker.Action.PICKED) {
            console.info(data.docs);
            callback(data.docs);
          }
        })
        .build();
      picker.setVisible(true);
    }
  }

  return { open };
}
