import React from "react";
import PropTypes from "prop-types";
import { useHistory, useRouteMatch } from "react-router-dom";
import useModal from "../../hooks/useModal";
import { confirm, notify } from "../../lib/utils";
import Modal from "../Modal";
import Write from "./Write";

function Header({
  request,
  auth,
  updateRequest,
  deleteRequest,
  isAdmin,
  isRequester,
}) {
  const history = useHistory();
  const [ref, isOpen, open, close] = useModal();
  const match = useRouteMatch("/requests/:id");

  async function handleToggle() {
    const open = !request.open;
    await updateRequest(request, { open });
    notify(`Request ${open ? "opened" : "closed"}`);
  }

  async function handleDelete() {
    if (await confirm("Delete request?")) {
      await deleteRequest(request);
      if (match) history.push("/");
      notify("Request deleted");
    }
  }

  return (
    <div className="uk-grid-small uk-flex-between" data-uk-grid>
      <div className="uk-flex uk-flex-middle">
        <div>
          <img
            className="uk-border-circle"
            width="40"
            height="40"
            src={request.user.photo}
            alt={request.user.name}
          />
        </div>
        <div className="uk-margin-small-left uk-text-bold uk-visible@m">
          {request.user.name}
        </div>
      </div>
      <div className="uk-flex">
        {isAdmin && (
          <div>
            <button
              type="button"
              className={`uk-icon-button ${request.open ? "active" : ""}`}
              data-uk-icon="star"
              onClick={handleToggle}
              data-uk-tooltip="toggle open"
            ></button>
          </div>
        )}
        {(isRequester || isAdmin) && (
          <>
            <div className="uk-margin-small-left">
              <button
                type="button"
                className="uk-icon-button"
                data-uk-icon="pencil"
                onClick={open}
                data-uk-tooltip={
                  request.open ? "cannot edit open request" : "edit request"
                }
                disabled={request.open}
              ></button>
              <Modal ref={ref} isOpen={isOpen} close={close}>
                {() => (
                  <Write
                    auth={auth}
                    request={request}
                    createUpdateRequest={updateRequest}
                    closeModal={close}
                  />
                )}
              </Modal>
            </div>
            <div className="uk-margin-small-left">
              <button
                type="button"
                className="uk-icon-button"
                data-uk-icon="trash"
                onClick={handleDelete}
                data-uk-tooltip={
                  request.open ? "cannot delete open request" : "delete request"
                }
                disabled={request.open}
              ></button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

Header.propTypes = {
  request: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  updateRequest: PropTypes.func.isRequired,
  deleteRequest: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isRequester: PropTypes.bool.isRequired,
};

export default Header;
