import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  checkChanges,
  notify,
  validateMultiSelect,
  validateNumber,
  validateRadio,
  validateSingleSelect,
} from "../../lib/utils";
import Number from "../Form/Number";
import Radio from "../Form/Radio";
import SingleSelect from "../Form/SingleSelect";
import MultiSelect from "../Form/MultiSelect";
import {
  COUNTRIES,
  MIN_AGE,
  MAX_AGE,
  GENDERS,
  LANGUAGES,
  MAX_LANGUAGES,
  MIN_LANGUAGES,
} from "../../lib/constants";

function Demographic({ user, updateUser }) {
  const initialize = () => ({
    age: user.age || 0,
    gender: user.gender || "",
    country: user.country || null,
    languages: user.languages || [],
  });
  const [demographic, setDemographic] = useState(initialize);
  const changed = checkChanges(initialize(), demographic);
  const valid =
    validateNumber(demographic.age, MIN_AGE, MAX_AGE) &&
    validateRadio(demographic.gender, GENDERS) &&
    validateSingleSelect(demographic.country, COUNTRIES) &&
    validateMultiSelect(demographic.languages, MIN_LANGUAGES, MAX_LANGUAGES);
  const savable = changed && valid;

  function handleChange(label, value) {
    setDemographic((state) => ({ ...state, [label]: value }));
  }

  async function handleUpdate(e) {
    e.preventDefault();
    await updateUser(user, demographic);
    notify("Demographic updated");
  }

  return (
    <form
      className="uk-form-stacked uk-card uk-card-default uk-card-hover"
      onSubmit={handleUpdate}
    >
      <div className="uk-card-body">
        <div className="uk-child-width-1-3@m" data-uk-grid>
          <div>
            <Number
              label="How old are you"
              field="age"
              value={demographic.age}
              handleChange={handleChange}
              min={MIN_AGE}
              max={MAX_AGE}
              unit="years"
              required
            />
          </div>
          <div>
            <Radio
              label="What is your gender"
              field="gender"
              value={demographic.gender}
              options={GENDERS}
              handleChange={handleChange}
              required
            />
          </div>
          <div>
            <SingleSelect
              label="What country do you live in"
              field="country"
              value={demographic.country}
              handleChange={handleChange}
              options={COUNTRIES}
              placeholder="select country"
              required
            />
          </div>
          <div className="uk-width-1-2@m">
            <MultiSelect
              label="What languages are you most proficient in"
              field="languages"
              value={demographic.languages}
              handleChange={handleChange}
              options={LANGUAGES}
              placeholder="select languages"
              minOptions={MIN_LANGUAGES}
              maxOptions={MAX_LANGUAGES}
              required
            />
          </div>
        </div>
      </div>

      <div className="uk-card-footer uk-text-right">
        <button
          className="uk-button uk-button-primary"
          type="submit"
          disabled={!savable}
        >
          update
        </button>
      </div>
    </form>
  );
}

Demographic.propTypes = {
  user: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
};

export default Demographic;
