import PropTypes from "prop-types";
import ReactSelect from "react-select";
import { getLabel } from "../../lib/utils";

function SingleSelect({
  label,
  field,
  value,
  handleChange,
  options,
  placeholder,
  required,
}) {
  return (
    <div>
      <label className="uk-form-label">{getLabel(label, required)}</label>
      <div className="uk-form-controls">
        <ReactSelect
          value={value}
          onChange={(value) => handleChange(field, value)}
          options={options}
          placeholder={placeholder}
          className="react-select-container"
          classNamePrefix="react-select"
          isClearable
        />
      </div>
    </div>
  );
}

SingleSelect.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
};

export default SingleSelect;
