import React from "react";
import PropTypes from "prop-types";

function Spinner({ full }) {
  return (
    <div
      className={`uk-flex uk-flex-middle uk-flex-center ${
        !full ? "uk-height-small" : ""
      }`}
      {...(full && {
        "data-uk-height-viewport": "offset-top: true",
      })}
    >
      <div data-uk-spinner="ratio: 3"></div>
    </div>
  );
}

Spinner.propTypes = {
  full: PropTypes.bool,
};

export default Spinner;
