import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  MAX_REQUEST_DURATION,
  MAX_REQUEST_RATE,
  MAX_REQUEST_REVIEWERS,
  MIN_REQUEST_DURATION,
  MIN_REQUEST_RATE,
  MIN_REQUEST_REVIEWERS,
  REQUEST_TYPES,
} from "../../lib/constants";
import { validateNumber, validateRadio } from "../../lib/utils";
import Number from "../Form/Number";
import Radio from "../Form/Radio";
import Spinner from "../Spinner";
import useStripe from "../../hooks/useStripe";

function Review({
  auth,
  request,
  closeModal,
  order,
  createOrder,
  updateRequest,
}) {
  const { createCheckout, orders } = useStripe(auth, request);
  const [state, setState] = useState({
    kind: order?.kind || "",
    reviewers: order?.reviewers || 0,
    duration: order?.duration || 0,
    rate: order?.rate || 0,
  });
  const [processing, setProcessing] = useState(false);
  const valid =
    validateRadio(state.kind, REQUEST_TYPES) &&
    validateNumber(
      state.reviewers,
      MIN_REQUEST_REVIEWERS,
      MAX_REQUEST_REVIEWERS
    ) &&
    validateNumber(
      state.duration,
      MIN_REQUEST_DURATION,
      MAX_REQUEST_DURATION
    ) &&
    validateNumber(state.rate, MIN_REQUEST_RATE, MAX_REQUEST_RATE);
  const disabled = Boolean(orders.length);
  const savable = !disabled && valid;
  const amount = valid ? state.reviewers * state.duration * state.rate : 0;

  function handleChange(key, value) {
    setState((state) => ({ ...state, [key]: value }));
  }

  async function handleSave(e) {
    e.preventDefault();
    setProcessing(true);
    let session;
    if (amount)
      session = await createCheckout(
        {
          name: "Feedback Request",
          description: `${state.kind} feedback | ${state.reviewers} reviewers | ${state.duration}hrs | $${state.rate}/hr`,
          images: [],
          quantity: 1,
          amount: amount * 100,
          currency: "USD",
        },
        { isRequest: true, requestId: request.id }
      );
    await Promise.all([
      createOrder({
        request: request.id,
        ...state,
        amount,
        currency: "USD",
        isRequest: true,
      }),
      updateRequest(request, { ...state, amount }),
    ]);
    setProcessing(false);
    closeModal();
    if (session) window.location.assign(session.url);
  }

  if (processing) return <Spinner />;

  return (
    <form className="uk-form-stacked" onSubmit={handleSave}>
      <div className="uk-modal-body">
        <h5 className="uk-margin uk-text-uppercase">
          How can our reviewers can help you with your document?
        </h5>

        <div className="uk-margin">
          <Radio
            label="What kind of feedback are you looking for"
            field="kind"
            value={state.kind}
            options={REQUEST_TYPES}
            handleChange={handleChange}
            required
            disabled={disabled}
          />
        </div>

        <div className="uk-margin uk-text-meta">
          Paying reviewers for their feedback greatly increases your chances of
          faster and higher quality feedback.
        </div>

        <div className="uk-margin uk-child-width-expand@m" data-uk-grid>
          <div>
            <Number
              label="How many reviewers do you want to provide you with feedback"
              field="reviewers"
              value={state.reviewers}
              handleChange={handleChange}
              min={MIN_REQUEST_REVIEWERS}
              max={MAX_REQUEST_REVIEWERS}
              unit="reviewers"
              required
              disabled={disabled}
            />
          </div>
          <div>
            <Number
              label="How much time should a reviewer spend reviewing and providing feedback"
              field="duration"
              value={state.duration}
              handleChange={handleChange}
              min={MIN_REQUEST_DURATION}
              max={MAX_REQUEST_DURATION}
              unit="hours"
              required
              disabled={disabled}
            />
          </div>
          <div>
            <Number
              label="How much do you want to pay a reviewer"
              field="rate"
              value={state.rate}
              handleChange={handleChange}
              min={MIN_REQUEST_RATE}
              max={MAX_REQUEST_RATE}
              unit="USD"
              required
              disabled={disabled}
            />
          </div>
        </div>

        <div className="uk-margin uk-text-meta">
          A reviewer gets paid when you accept their feedback. When you reject a
          reviewer's feedback, we promptly assign another qualified reviewer.
        </div>

        <div className="uk-margin uk-text-meta">
          You have 24hrs after a reviewer's feedback is submitted to either
          accept or reject it, after which no further action/change can be
          taken, and the feedback automatically accepted.
        </div>
      </div>

      <div className="uk-modal-footer">
        <div className="uk-text-right">
          <span>${amount}</span>
          <button
            className="uk-margin-small-left uk-button uk-button-primary"
            type="submit"
            disabled={!savable}
          >
            submit request
          </button>
        </div>
      </div>
    </form>
  );
}

Review.propTypes = {
  request: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  order: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  createOrder: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired,
};

export default Review;
