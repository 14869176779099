import React, { useState } from "react";
import PropTypes from "prop-types";
import { confirm, checkChanges, notify } from "../../lib/utils";
import Upload from "../Attachment/Upload";
import Record from "../Attachment/Record";
import Google from "../Attachment/Google";
import View from "../Attachment/View";
import Spinner from "../Spinner";

function Documents({ user, auth, updateUser }) {
  const [documents, setDocuments] = useState(user.documents || []);
  const [uploading, setUploading] = useState(false);
  const valid = documents.length;
  const changed = checkChanges(user.documents, documents);
  const savable = changed && valid;

  function handleAdd(document) {
    setDocuments((documents) => [...documents, document]);
  }

  async function handleRemove(document) {
    if (await confirm("Remove attachment?")) {
      setDocuments((documents) =>
        documents.filter((d) => d.url !== document.url)
      );
    }
  }

  async function handleUpdate(e) {
    e.preventDefault();
    await updateUser(user, { documents });
    notify("Documents updated");
  }

  if (uploading) return <Spinner />;

  return (
    <form
      className="uk-form-stacked uk-card uk-card-default uk-card-hover"
      onSubmit={handleUpdate}
    >
      <div className="uk-card-body">
        <h5 className="uk-margin uk-text-italic">
          Do you have documents, files or recordings that could help us
          understand your expertise better? E.g. doc/audio/video resume,
          portfolio, degree, diploma, certifications, awards, introduction
          audio/video e.t.c.
        </h5>

        <div className="uk-margin">
          <ul data-uk-tab="">
            <li>
              <a href="#select">upload</a>
            </li>
            <li>
              <a href="#record">record</a>
            </li>
            <li>
              <a href="#google">google</a>
            </li>
          </ul>
          <ul className="uk-switcher">
            <li>
              <Upload
                handleChange={handleAdd}
                auth={auth}
                setUploading={setUploading}
              />
            </li>
            <li>
              <Record
                handleChange={handleAdd}
                auth={auth}
                setUploading={setUploading}
              />
            </li>
            <li>
              <Google
                handleChange={handleAdd}
                auth={auth}
                setUploading={setUploading}
              />
            </li>
          </ul>
        </div>

        <div className="uk-margin" data-uk-grid>
          {documents.map((document) => (
            <div key={document.url}>
              <div className="uk-margin-small">
                <View attachment={document} className="medium" />
              </div>
              <div className="uk-margin-small">
                <button
                  type="button"
                  className="uk-button uk-button-small"
                  onClick={() => handleRemove(document)}
                >
                  delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="uk-card-footer uk-text-right">
        <button
          className="uk-button uk-button-primary"
          type="submit"
          disabled={!savable}
        >
          update
        </button>
      </div>
    </form>
  );
}

Documents.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
};

export default Documents;
