import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { notify } from "../lib/utils";
import Center from "../components/Center";

function Success() {
  const { searchParams } = new URL(window.location.href);
  const sessionId = searchParams.get("session_id");
  const history = useHistory();

  useEffect(() => {
    function effect() {
      history.push("/success");
      notify("Order successful!");
    }
    if (sessionId) effect();
  }, [sessionId, history]);

  return (
    <div className="uk-section uk-section-muted">
      <div className="uk-container">
        <Center>
          <h2 className="uk-margin uk-text-success">
            Feedback request submitted! 🙌
          </h2>
          <p className="uk-margin">
            Qualified reviewers will promptly start providing you with feedback.
          </p>
          <div className="uk-margin">
            <Link to="/" className="uk-button uk-button-default">
              Back to home
            </Link>
          </div>
        </Center>
      </div>
    </div>
  );
}

export default Success;
