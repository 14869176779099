import React from "react";
import PropTypes from "prop-types";
import { useRouteMatch, Link } from "react-router-dom";
import useModal from "../../hooks/useModal";
import Modal from "../Modal";
import Reviewers from "../User/Reviewers";
import Review from "./Review";

function Footer({
  request,
  auth,
  isRequester,
  isAdmin,
  order,
  createOrder,
  updateRequest,
}) {
  const [refInvites, isOpenInvites, openInvites, closeInvites] = useModal();
  const [refReview, isOpenReview, openReview, closeReview] = useModal();
  const match = useRouteMatch("/requests/:id");

  return (
    <div className="uk-grid-small uk-child-width-expand@m" data-uk-grid>
      <div>
        <Link
          to={match ? "/requests" : `/requests/${request.id}`}
          className="uk-button uk-button-default uk-width-expand"
        >
          {match ? "go home" : "view replies"}
        </Link>
      </div>
      {(isRequester || isAdmin) && (
        <div>
          <button
            type="button"
            className="uk-button uk-button-default uk-width-expand"
            onClick={openReview}
          >
            get feedback
          </button>
          <Modal ref={refReview} isOpen={isOpenReview} close={closeReview}>
            {() => (
              <Review
                auth={auth}
                request={request}
                closeModal={closeReview}
                updateRequest={updateRequest}
                order={order}
                createOrder={createOrder}
              />
            )}
          </Modal>
        </div>
      )}
      {isAdmin && (
        <div>
          <button
            type="button"
            className="uk-button uk-button-default uk-width-expand"
            onClick={openInvites}
          >
            invite reviewers
          </button>
          <Modal ref={refInvites} isOpen={isOpenInvites} close={closeInvites}>
            {() => <Reviewers auth={auth} request={request} />}
          </Modal>
        </div>
      )}
    </div>
  );
}

Footer.propTypes = {
  request: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  isRequester: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  order: PropTypes.object,
  createOrder: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired,
};

export default Footer;
