import PropTypes from "prop-types";
import { getLabel } from "../../lib/utils";

function Radio({
  label,
  field,
  value,
  options,
  handleChange,
  required,
  disabled,
}) {
  function handler(e) {
    handleChange(field, e.target.value);
  }

  return (
    <div>
      <div className="uk-form-label">{getLabel(label, required)}</div>
      <div className="uk-form-controls uk-grid-small" data-uk-grid>
        {options.map((option) => (
          <label key={option}>
            <input
              className="uk-radio"
              type="radio"
              name={label}
              value={option}
              checked={value === option}
              onChange={handler}
              required={required}
              disabled={disabled}
            />{" "}
            {option}
          </label>
        ))}
      </div>
    </div>
  );
}

Radio.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Radio;
