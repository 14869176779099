import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const Modal = forwardRef(({ children, isOpen, close }, ref) => (
  <div
    ref={ref}
    data-uk-modal="container: #root; stack: true; esc-close: false; bg-close: false"
    className="uk-flex-top"
  >
    <div className="uk-modal-dialog uk-margin-auto-vertical">
      {close && (
        <button
          type="button"
          className="uk-modal-close-default"
          data-uk-icon="close"
          onClick={close}
        />
      )}
      {isOpen && children()}
    </div>
  </div>
));

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func,
  children: PropTypes.func.isRequired,
};

export default Modal;
