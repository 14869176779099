import React from "react";
import PropTypes from "prop-types";

function Center({ children }) {
  return (
    <div
      className="uk-flex uk-flex-middle uk-flex-center"
      data-uk-height-viewport="expand: true"
    >
      <div className="uk-text-center">{children}</div>
    </div>
  );
}

Center.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Center;
