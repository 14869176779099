/* eslint-disable promise/no-callback-in-promise */
import { useEffect, useState } from "react";
import GOOGLE from "./google";
import useScript from "./useScript";
import useIsMounted from "./useIsMounted";

export default function useGoogle(auth) {
  const loaded = useScript("https://apis.google.com/js/api.js");
  const [accessToken, setAccessToken] = useState("");
  const [idToken, setIdToken] = useState("");
  const [loading, setLoading] = useState(true);
  const isMounted = useIsMounted();

  useEffect(() => {
    function update(user) {
      const { access_token, id_token } = user.getAuthResponse(true) || {};
      setIdToken(id_token);
      setAccessToken(access_token);
    }

    function callback() {
      if (isMounted()) {
        const auth2 = window.gapi.auth2.getAuthInstance();
        auth2.currentUser.listen(update);
        update(auth2.currentUser.get());
        setLoading(false);
      }
    }

    if (loaded && window.gapi) {
      if (window.gapi.auth2) {
        callback();
      } else {
        window.gapi.load("auth2:picker:client", () => {
          window.gapi.client
            .init({
              apiKey: GOOGLE.API_KEY,
              clientId: GOOGLE.ADOBE_CLIENT_ID,
              discoveryDocs: GOOGLE.DISCOVERY_DOCS,
              scope: GOOGLE.SCOPES,
              loginHint: auth?.email,
            })
            .then(callback)
            .catch(console.error);
        });
      }
    }
  }, [loaded, auth, isMounted]);

  async function authorize() {
    await window.gapi.auth2.getAuthInstance().signIn();
  }

  async function revoke() {
    await window.gapi.auth2.getAuthInstance().disconnect();
  }

  return { accessToken, idToken, authorize, revoke, loading };
}
