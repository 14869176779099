import PropTypes from "prop-types";
import ReactSelect from "react-select";
import { getLabel } from "../../lib/utils";

function MultiSelect({
  label,
  field,
  value,
  handleChange,
  options,
  placeholder,
  minOptions,
  maxOptions,
  required,
}) {
  return (
    <div>
      <label className="uk-form-label">{getLabel(label, required)}</label>
      <div className="uk-form-controls">
        <ReactSelect
          isMulti
          value={value}
          onChange={(value) => handleChange(field, value || [])}
          options={value.length === maxOptions ? [] : options}
          noOptionsMessage={() =>
            value.length === maxOptions
              ? "Maximum options selected"
              : "No options available"
          }
          placeholder={placeholder}
          className="react-select-container"
          classNamePrefix="react-select"
          isClearable
        />
        <div className="uk-text-meta">
          {minOptions}-{maxOptions} options
        </div>
      </div>
    </div>
  );
}

MultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  minOptions: PropTypes.number.isRequired,
  maxOptions: PropTypes.number.isRequired,
  required: PropTypes.bool,
};

export default MultiSelect;
