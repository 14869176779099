import { sort } from "./utils";
import countries from "./countries.json";
import languages from "./languages.json";
import topics from "./topics.json";

// environment

export const IS_PRODUCTION = process.env.NODE_ENV === "production";
export const CONVERT_KEY = IS_PRODUCTION
  ? process.env.REACT_APP_CONVERT_PROD
  : process.env.REACT_APP_CONVERT_DEV;

// data

export const COUNTRIES = sort(countries, "name").map((country) => ({
  label: country.name,
  value: country.code,
}));
export const LANGUAGES = sort(languages, "name").map((language) => ({
  label: language.name,
  value: language.code,
}));
export const TOPICS = topics.sort().map((topic) => ({
  label: topic,
  value: topic,
}));

// files

export const PDF_MIMETYPE = "application/pdf";
export const DOCUMENT_TYPES = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/pdf",
];
export const MAX_DURATION = 60 * 30;

// form

export const TEXT_MIN = 2;
export const TEXT_MAX = 100;
export const TEXTAREA_MIN = 10;
export const TEXTAREA_MAX = 300;

// requests

export const REQUEST_TYPES = [
  "organization/structure",
  "content feedback",
  "grammar/language",
  "any useful feedback",
];
export const MIN_REQUEST_TOPICS = 1;
export const MAX_REQUEST_TOPICS = 2;
export const MIN_REQUEST_REVIEWERS = 1;
export const MAX_REQUEST_REVIEWERS = 5;
export const MIN_REQUEST_DURATION = 1;
export const MAX_REQUEST_DURATION = 3;
export const MIN_REQUEST_RATE = 0;
export const MAX_REQUEST_RATE = 500;

// demographic

export const MIN_AGE = 18;
export const MAX_AGE = 70;
export const GENDERS = ["male", "female"];
export const MIN_LANGUAGES = 1;
export const MAX_LANGUAGES = 2;

// reviewer

export const MIN_REVIEWER_HOURS = 1;
export const MAX_REVIEWER_HOURS = 8;
export const MIN_REVIEWER_TOPICS = 1;
export const MAX_REVIEWER_TOPICS = 2;
export const MIN_REVIEWER_RATE = 0;
export const MAX_REVIEWER_RATE = 500;
