import { useState, useEffect } from "react";
import {
  firestore,
  serverTimestamp,
  arrayRemove,
  arrayUnion,
  getDoc,
  getDocs,
} from "../lib/firebase";

const REF = firestore.collection("requests");
const LIMIT = 100;

export default function useRequests(auth, { id, home, admin }) {
  const [requests, setRequests] = useState([]);
  const [invites, setInvites] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function effect() {
      const unsubscribe = REF.doc(id).onSnapshot((snapshot) => {
        setRequests([getDoc(snapshot)]);
        setLoading(false);
      });
      return unsubscribe;
    }
    if (auth && id) return effect();
    else setLoading(false);
  }, [id, auth]);

  useEffect(() => {
    function effect() {
      let query;
      if (home) query = REF.where("user.uid", "==", auth.uid);
      if (admin) query = REF;
      if (query) {
        const unsubscribe = query
          .orderBy("created", "desc")
          .limit(LIMIT)
          .onSnapshot((snapshot) => {
            setRequests(getDocs(snapshot));
            setLoading(false);
          });
        return unsubscribe;
      }
    }
    if (auth) return effect();
    else setLoading(false);
  }, [admin, auth, home]);

  useEffect(() => {
    function effect() {
      const unsubscribe = REF.where("invites", "array-contains", auth.uid)
        .orderBy("created", "desc")
        .limit(LIMIT)
        .onSnapshot((snapshot) => {
          setInvites(getDocs(snapshot));
        });
      return unsubscribe;
    }
    if (auth && home) return effect();
  }, [auth, home]);

  async function createRequest(data) {
    await REF.add({ ...data, user: auth, created: serverTimestamp() });
  }

  async function updateRequest(request, data) {
    await REF.doc(request.id).update({ ...data, updated: serverTimestamp() });
  }

  async function deleteRequest(request) {
    await REF.doc(request.id).delete();
  }

  async function addInvite(request, uid) {
    await REF.doc(request.id).update({
      invites: arrayUnion(uid),
    });
  }

  async function removeInvite(request, uid) {
    await REF.doc(request.id).update({
      invites: arrayRemove(uid),
    });
  }

  return {
    requests,
    invites,
    loading,
    createRequest,
    updateRequest,
    deleteRequest,
    addInvite,
    removeInvite,
  };
}
