import React from "react";
import PropTypes from "prop-types";
import View from "../Attachment/View";
import { linkify } from "../../lib/utils";

function Reply({ reply, setReply }) {
  function handleClose(e) {
    e.preventDefault();
    setReply(null);
  }

  return (
    <a
      href={`#${reply.id}`}
      className="reply uk-flex uk-flex-between uk-flex-middle"
    >
      {reply.description && (
        <span
          className="uk-text-truncate uk-text-meta"
          dangerouslySetInnerHTML={{ __html: linkify(reply.description) }}
        />
      )}
      {reply.attachment && (
        <View attachment={reply.attachment} className="small" />
      )}
      {setReply && (
        <span>
          <button
            type="button"
            className="uk-icon-button"
            data-uk-icon="close"
            onClick={handleClose}
          ></button>
        </span>
      )}
    </a>
  );
}

Reply.propTypes = {
  reply: PropTypes.object.isRequired,
  setReply: PropTypes.func,
};

export default Reply;
