import React from "react";
import PropTypes from "prop-types";

function Landing({ signIn }) {
  return <div>landing</div>;
}

Landing.propTypes = {
  signIn: PropTypes.func.isRequired,
};

export default Landing;
