import { useEffect, useState } from "react";
import { firestore, getDocs } from "../lib/firebase";

const REF = firestore.collection("users");
const LIMIT = 100;

function useUsers(auth) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function effect() {
      const unsubscribe = REF.orderBy("description")
        .limit(LIMIT)
        .onSnapshot((snapshot) => {
          setUsers(getDocs(snapshot));
          setLoading(false);
        });
      return unsubscribe;
    }
    if (auth) return effect();
  }, [auth]);

  return { users, loading };
}

export default useUsers;
