import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import useRequests from "../hooks/useRequests";
import Spinner from "../components/Spinner";
import RequestRead from "../components/Request/Read";
import ReplyThread from "../components/Reply/Thread";
import ReplyWrite from "../components/Reply/Write";
import useReplies from "../hooks/useReplies";
import useAudioVideo from "../hooks/useAudioVideo";
import View from "../components/Attachment/View";
import { checkAudioVideo } from "../lib/utils";

function Request({ auth }) {
  const { id } = useParams();
  const {
    requests,
    loading: loadingRequest,
    updateRequest,
    deleteRequest,
  } = useRequests(auth, { id });
  const [request] = requests;
  const {
    threads,
    createReply,
    deleteReply,
    loading: loadingReplies,
  } = useReplies(auth, request, {
    all: auth.admin || request.user.uid === auth.uid,
  });
  const [reply, setReply] = useState(null);
  const [refAttachment, seek, timeUpdate, currentTime, duration] =
    useAudioVideo();
  const ref = useRef(null);

  useEffect(() => {
    if (reply)
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
  }, [reply]);

  if (loadingRequest || loadingReplies) return <Spinner full />;

  if (!request) return null;

  const isAudioVideo = checkAudioVideo(request.attachment);

  return (
    <div
      className="uk-section uk-section-muted"
      data-uk-height-viewport="offset-top: true"
    >
      <div className="uk-container">
        <div className="uk-margin-medium-bottom">
          <h2 className="uk-margin">
            {request.open ? "Open" : ""} Feedback Request
          </h2>
          <div className="uk-margin">
            <RequestRead
              auth={auth}
              request={request}
              updateRequest={updateRequest}
              deleteRequest={deleteRequest}
            />
          </div>

          <div className="uk-margin-medium-bottom" data-uk-grid>
            {request.attachment && (
              <div className="uk-width-3-5@m">
                <div className="sticky">
                  <View
                    ref={refAttachment}
                    attachment={request.attachment}
                    className="full"
                    timeUpdate={timeUpdate}
                  />
                </div>
              </div>
            )}
            <div className="uk-width-expand@m uk-overflow-auto">
              <div
                ref={ref}
                className="uk-margin uk-card uk-card-default uk-card-hover uk-card-small"
              >
                <ReplyWrite
                  reply={reply}
                  setReply={setReply}
                  auth={auth}
                  createUpdateReply={createReply}
                  request={request}
                  updateRequest={updateRequest}
                  isAudioVideo={isAudioVideo}
                  currentTime={currentTime}
                  duration={duration}
                />
              </div>
              <ul className="uk-margin uk-list uk-list-large">
                {threads.map((thread) => (
                  <li key={thread[0].user.uid}>
                    <ReplyThread
                      request={request}
                      thread={thread}
                      auth={auth}
                      deleteReply={deleteReply}
                      setReply={setReply}
                      updateRequest={updateRequest}
                      seek={seek}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Request.propTypes = {
  auth: PropTypes.object.isRequired,
};

export default Request;
