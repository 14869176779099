import React from "react";
import PropTypes from "prop-types";
import useUsers from "../../hooks/useUsers";
import useRequests from "../../hooks/useRequests";
import { notify, confirm } from "../../lib/utils";
import Spinner from "../Spinner";
import Read from "./Read";

function Reviewers({ auth, request }) {
  const { users, loading } = useUsers(auth);
  const { addInvite, removeInvite } = useRequests(auth, {});
  const invites = request.invites || [];
  const invited = users.filter((u) => invites.includes(u.uid));
  const uninvited = users.filter(
    (u) => !invites.includes(u.uid) && u.uid !== auth.uid
  );

  async function handleInvite(user) {
    await addInvite(request, user.uid);
    notify("User invited");
  }

  async function handleCancel(user) {
    if (await confirm(`Uninvite ${user.name}?`)) {
      await removeInvite(request, user.uid);
      notify("User uninvited");
    }
  }

  if (loading) return <Spinner />;

  return (
    <div className="uk-modal-body">
      <ul data-uk-tab="">
        <li>
          <a href="#uninvited">Uninvited ({uninvited.length})</a>
        </li>
        <li>
          <a href="#invited">Invited ({invited.length})</a>
        </li>
      </ul>
      <ul className="uk-switcher">
        <li>
          <ul className="uk-list uk-list-divider">
            {uninvited.map((user) => (
              <li key={user.id}>
                <div>
                  <Read user={user} />
                  <div className="uk-text-right">
                    <button
                      onClick={() => handleInvite(user)}
                      type="button"
                      className="uk-button uk-button-primary uk-button-small"
                    >
                      invite
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <ul className="uk-list uk-list-divider">
            {invited.map((user) => (
              <li key={user.id}>
                <div>
                  <Read user={user} />
                  <div className="uk-text-right">
                    <button
                      onClick={() => handleCancel(user)}
                      type="button"
                      className="uk-button uk-button-danger uk-button-small"
                    >
                      remove
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );
}

Reviewers.propTypes = {
  auth: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
};

export default Reviewers;
