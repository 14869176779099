import React from "react";
import PropTypes from "prop-types";
import RequestRead from "../components/Request/Read";
import useRequests from "../hooks/useRequests";

function Admin({ auth }) {
  const { requests, updateRequest, deleteRequest } = useRequests(auth, {
    admin: true,
  });

  return (
    <div
      className="uk-section uk-section-muted"
      data-uk-height-viewport="offset-top: true"
    >
      <div className="uk-container">
        <div>
          <h2 className="uk-margin">All Feedback Requests</h2>
          <ul className="uk-margin uk-list uk-list-large">
            {requests.map((request) => (
              <li key={request.id}>
                <RequestRead
                  auth={auth}
                  request={request}
                  updateRequest={updateRequest}
                  deleteRequest={deleteRequest}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

Admin.propTypes = {
  auth: PropTypes.object.isRequired,
};

export default Admin;
