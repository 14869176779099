import React from "react";
import PropTypes from "prop-types";
import { arrayRemove, arrayUnion } from "../../lib/firebase";
import { notify } from "../../lib/utils";

function Footer({ user, request, review, updateReview, updateRequest }) {
  async function handleAccept() {
    await updateReview({ user, accepted: true, rejected: false });
    await updateRequest(request, {
      accepted: arrayUnion(user.uid),
      rejected: arrayRemove(user.uid),
    });
    notify("Review accepted");
  }

  async function handleReject() {
    await updateReview({ user, rejected: true, accepted: false });
    await updateRequest(request, {
      rejected: arrayUnion(user.uid),
      accepted: arrayRemove(user.uid),
    });
    notify("Review rejected");
  }

  return (
    <div className="uk-grid-small uk-child-width-expand" data-uk-grid>
      <div>
        <button
          type="button"
          className="uk-button uk-button-danger uk-button-small uk-width-expand"
          onClick={handleReject}
          disabled={!request.open || review?.rejected}
          data-uk-tooltip={
            !request.open ? "cannot reject when closed" : "reject review"
          }
        >
          {review?.rejected ? "rejected" : "reject"}
        </button>
      </div>
      <div>
        <button
          type="button"
          className="uk-button uk-button-primary uk-button-small uk-width-expand"
          onClick={handleAccept}
          disabled={!request.open || review?.accepted}
          data-uk-tooltip={
            !request.open ? "cannot accept when closed" : "accept review"
          }
        >
          {review?.accepted ? "accepted" : "accept"}
        </button>
      </div>
    </div>
  );
}

Footer.propTypes = {
  user: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  review: PropTypes.object,
  updateReview: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired,
};

export default Footer;
