import React from "react";
import PropTypes from "prop-types";
import useReview from "../../hooks/useReview";
import Read from "./Read";
import Header from "./Header";
import Footer from "./Footer";

function Thread({
  thread,
  auth,
  request,
  deleteReply,
  setReply,
  updateRequest,
  seek,
}) {
  const user = thread[0].user;
  const { review, updateReview } = useReview(auth, request, user);
  const isAdmin = auth.admin;
  const isRequester = auth.uid === request.user.uid;
  const isUser = auth.uid === user.uid;
  const isRequesterUser = request.user.uid === user.uid;
  const showFooter = (isRequester || isAdmin) && !isRequesterUser;
  const showBadge = isRequester || isAdmin || isUser;

  return (
    <div className="uk-card uk-card-default uk-card-hover uk-card-small">
      <div className="uk-card-header">
        <Header user={user} review={review} />
        {showBadge && (
          <>
            {review?.accepted && (
              <div className="uk-card-badge uk-label uk-label-primary">
                accepted
              </div>
            )}
            {review?.rejected && (
              <div className="uk-card-badge uk-label uk-label-danger">
                rejected
              </div>
            )}
          </>
        )}
      </div>
      <div className="uk-card-body">
        <ul className="uk-list uk-list-divider">
          {thread.map((reply) => (
            <li key={reply.id}>
              <Read
                reply={reply}
                request={request}
                auth={auth}
                deleteReply={deleteReply}
                setReply={setReply}
                isAdmin={isAdmin}
                seek={seek}
              />
            </li>
          ))}
        </ul>
      </div>
      {showFooter && (
        <div className="uk-card-footer">
          <Footer
            user={user}
            request={request}
            review={review}
            updateReview={updateReview}
            updateRequest={updateRequest}
          />
        </div>
      )}
    </div>
  );
}

Thread.propTypes = {
  request: PropTypes.object.isRequired,
  thread: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired,
  deleteReply: PropTypes.func.isRequired,
  setReply: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired,
  seek: PropTypes.func.isRequired,
};

export default Thread;
