import React from "react";
import PropTypes from "prop-types";
import { getLabel } from "../../lib/utils";

function Textarea({
  label,
  field,
  value,
  handleChange,
  placeholder,
  minLength,
  maxLength,
  rows,
  required,
  disabled,
}) {
  return (
    <div>
      <label className="uk-form-label">{getLabel(label, required)}</label>
      <div className="uk-form-controls">
        <textarea
          className="uk-textarea"
          rows={rows}
          value={value}
          onChange={(e) => handleChange(field, e.target.value)}
          minLength={minLength}
          maxLength={maxLength}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
        />
        <div className="uk-text-meta">
          {minLength}-{maxLength} characters
        </div>
      </div>
    </div>
  );
}

Textarea.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  minLength: PropTypes.number.isRequired,
  maxLength: PropTypes.number.isRequired,
  rows: PropTypes.number.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Textarea;
