import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { notify } from "../../lib/utils";
import Upload from "../Attachment/Upload";
import Record from "../Attachment/Record";
import { increment } from "../../lib/firebase";
import Google from "../Attachment/Google";
import Spinner from "../Spinner";
import Reply from "./Reply";
import Text from "./Text";

function Write({
  auth,
  createUpdateReply,
  updateRequest,
  reply,
  request,
  setReply,
  isAudioVideo,
  currentTime,
  duration,
}) {
  const initialize = () => ({ description: "", time: 0 });
  const [state, setState] = useState(initialize);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    handleChange("time", currentTime);
  }, [currentTime]);

  async function handleSave(attachment) {
    await createUpdateReply({
      ...(attachment ? { attachment } : state),
      ...(reply && { reply }),
    });
    await updateRequest(request, { replies: increment(1) });
    setState(initialize);
    setReply(null);
    notify("Reply created");
  }

  function handleChange(key, value) {
    setState((state) => ({ ...state, [key]: value }));
  }

  if (uploading) return <Spinner />;

  return (
    <form className="uk-form-stacked">
      <div className="uk-card-body">
        {reply && <Reply reply={reply} setReply={setReply} />}
        <ul data-uk-tab="">
          <li>
            <a href="#text">text</a>
          </li>
          <li>
            <a href="#select">upload</a>
          </li>
          <li>
            <a href="#record">record</a>
          </li>
          <li>
            <a href="#google">google</a>
          </li>
        </ul>
        <ul className="uk-switcher">
          <li>
            <Text
              state={state}
              handleChange={handleChange}
              isAudioVideo={isAudioVideo}
              handleSave={handleSave}
              duration={duration}
              reply={reply}
            />
          </li>
          <li>
            <Upload
              handleChange={handleSave}
              auth={auth}
              required
              setUploading={setUploading}
            />
          </li>
          <li>
            <Record
              handleChange={handleSave}
              auth={auth}
              required
              setUploading={setUploading}
            />
          </li>
          <li>
            <Google
              handleChange={handleSave}
              auth={auth}
              required
              setUploading={setUploading}
            />
          </li>
        </ul>
      </div>
    </form>
  );
}

Write.propTypes = {
  auth: PropTypes.object.isRequired,
  createUpdateReply: PropTypes.func.isRequired,
  reply: PropTypes.object,
  request: PropTypes.object,
  setReply: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired,
  isAudioVideo: PropTypes.bool.isRequired,
  currentTime: PropTypes.number,
  duration: PropTypes.number,
};

export default Write;
