import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import {
  checkAudio,
  checkImage,
  checkVideo,
  checkPDF,
  webViewerURL,
} from "../../lib/utils";

const View = forwardRef(({ attachment, className, timeUpdate }, ref) => {
  if (checkImage(attachment.mimeType))
    return (
      <img src={attachment.url} alt={attachment.name} className={className} />
    );

  if (checkVideo(attachment.mimeType))
    return (
      <video
        ref={ref}
        src={attachment.url}
        controls
        className={className}
        onTimeUpdate={timeUpdate}
      />
    );

  if (checkAudio(attachment.mimeType))
    return (
      <audio
        ref={ref}
        src={attachment.url}
        controls
        className={className}
        onTimeUpdate={timeUpdate}
      />
    );

  if (checkPDF(attachment.mimeType))
    return (
      <div>
        <div className="uk-visible@m">
          <object
            data={attachment.url}
            type={attachment.mimeType}
            className={className}
          >
            <embed
              src={attachment.url}
              type={attachment.mimeType}
              className={className}
            />
          </object>
        </div>
        <div className="uk-hidden@m">
          <a
            className="uk-link-text"
            href={attachment.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            {attachment.name}
          </a>
        </div>
      </div>
    );

  return (
    <iframe
      className={className}
      title={attachment.name}
      src={webViewerURL(attachment.url)}
    />
  );
});

View.propTypes = {
  attachment: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  timeUpdate: PropTypes.func,
};

export default View;
