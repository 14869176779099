import React from "react";
import PropTypes from "prop-types";
import { linkify } from "../../lib/utils";

function Read({ user }) {
  return (
    <div>
      <div
        className="uk-margin-small uk-grid-small uk-flex-middle"
        data-uk-grid
      >
        <div>
          <img src={user.photo} alt="avatar" className="avatar-small" />
        </div>
        <div className="truncate">{user.name}</div>
      </div>

      {user.description && (
        <div
          className="uk-margin-small"
          dangerouslySetInnerHTML={{ __html: linkify(user.description) }}
        ></div>
      )}

      <div
        className="uk-margin-small uk-grid-small uk-child-width-auto uk-flex-middle"
        data-uk-grid
      >
        {user.age && (
          <div>
            <span className="uk-label">{user.age}</span>
          </div>
        )}
        {user.gender && (
          <div>
            <span className="uk-label">{user.gender}</span>
          </div>
        )}
        {user.country && (
          <div>
            <span className="uk-label">{user.country.label}</span>
          </div>
        )}
        {user.languages &&
          user.languages.map((language) => (
            <div key={language.label}>
              <span className="uk-label">{language.label}</span>
            </div>
          ))}
        {user.types &&
          user.types.map((type) => (
            <div key={type}>
              <span className="uk-label">{type}</span>
            </div>
          ))}
        {user.topics &&
          user.topics.map((topic) => (
            <div key={topic.label}>
              <span className="uk-label">{topic.label}</span>
            </div>
          ))}
        {user.rate && (
          <div>
            <span className="uk-label">${user.rate} / hr </span>
          </div>
        )}
        {user.hours && (
          <div>
            <span className="uk-label">{user.hours} hrs / day</span>
          </div>
        )}
      </div>
    </div>
  );
}

Read.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Read;
