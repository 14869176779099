import React from "react";
import PropTypes from "prop-types";
import useOrders from "../../hooks/useOrders";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";

function Read({ auth, request, updateRequest, deleteRequest }) {
  const { createOrder, orders } = useOrders(auth, { request });
  const [order] = orders;
  const isRequester = request.user.uid === auth.uid;
  const isAdmin = auth.admin;

  return (
    <div className="uk-card uk-card-default uk-card-hover">
      {(isRequester || isAdmin) && (
        <div className="uk-card-header">
          <Header
            auth={auth}
            request={request}
            updateRequest={updateRequest}
            deleteRequest={deleteRequest}
            isAdmin={isAdmin}
            isRequester={isRequester}
          />
        </div>
      )}
      <div className="uk-card-body">
        <Body request={request} order={order} />
      </div>
      {(isRequester || isAdmin) && (
        <div className="uk-card-footer">
          <Footer
            auth={auth}
            request={request}
            isRequester={isRequester}
            updateRequest={updateRequest}
            isAdmin={isAdmin}
            order={order}
            createOrder={createOrder}
          />
        </div>
      )}
    </div>
  );
}

Read.propTypes = {
  auth: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  updateRequest: PropTypes.func.isRequired,
  deleteRequest: PropTypes.func.isRequired,
};

export default Read;
