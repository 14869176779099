import React from "react";
import PropTypes from "prop-types";
import { TEXTAREA_MAX, TEXTAREA_MIN } from "../../lib/constants";
import { validateString } from "../../lib/utils";
import Number from "../Form/Number";
import Textarea from "../Form/Textarea";

function Text({ state, handleChange, isAudioVideo, handleSave, duration }) {
  const valid = validateString(state.description, TEXTAREA_MIN, TEXTAREA_MAX);

  return (
    <div>
      <div className="uk-margin-small">
        <Textarea
          label="What's your feedback response"
          field="description"
          value={state.description}
          handleChange={handleChange}
          minLength={TEXTAREA_MIN}
          maxLength={TEXTAREA_MAX}
          placeholder=""
          rows={2}
          required
        />
      </div>
      {isAudioVideo && (
        <div className="uk-margin-small uk-width-small">
          <Number
            label="time"
            field="time"
            value={state.time}
            handleChange={handleChange}
            min={0}
            max={duration}
            unit="seconds"
          />
        </div>
      )}
      <div className="uk-margin-small uk-text-right">
        <button
          type="button"
          className="uk-button uk-button-primary"
          onClick={() => handleSave()}
          disabled={!valid}
        >
          reply
        </button>
      </div>
    </div>
  );
}

Text.propTypes = {
  state: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  isAudioVideo: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  duration: PropTypes.number.isRequired,
};

export default Text;
