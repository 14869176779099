import React, { useState } from "react";
import PropTypes from "prop-types";
import View from "../Attachment/View";
import Textarea from "../Form/Textarea";
import CreateSelect from "../Form/CreateSelect";
import {
  TOPICS,
  MAX_REQUEST_TOPICS,
  MIN_REQUEST_TOPICS,
  TEXTAREA_MAX,
  TEXTAREA_MIN,
} from "../../lib/constants";
import {
  confirm,
  checkChanges,
  notify,
  validateMultiSelect,
  validateString,
} from "../../lib/utils";
import Upload from "../Attachment/Upload";
import Record from "../Attachment/Record";
import Google from "../Attachment/Google";
import Spinner from "../Spinner";

function Write({ request, auth, closeModal, createUpdateRequest }) {
  const initialize = (request) => ({
    description: request?.description || "",
    topics: request?.topics || [],
    attachment: request?.attachment || null,
  });
  const [state, setState] = useState(initialize(request));
  const [uploading, setUploading] = useState(false);
  const valid =
    validateString(state.description, TEXTAREA_MIN, TEXTAREA_MAX) &&
    validateMultiSelect(state.topics, MIN_REQUEST_TOPICS, MAX_REQUEST_TOPICS);
  const updated = checkChanges(initialize(request), state);
  const savable = valid && updated && !uploading;

  function handleChange(key, value) {
    setState((state) => ({ ...state, [key]: value }));
  }

  function handleAttachment(attachment) {
    handleChange("attachment", attachment);
  }

  async function handleRemove() {
    if (await confirm("Remove attachment?")) handleChange("attachment", null);
  }

  async function handleSave(e) {
    e.preventDefault();
    if (request) await createUpdateRequest(request, state);
    else await createUpdateRequest(state);
    setState(initialize());
    if (closeModal) closeModal();
    notify(`Request ${request ? "updated" : "created"}`);
  }

  return (
    <form className="uk-form-stacked" onSubmit={handleSave}>
      <div className={`uk-${closeModal ? "modal" : "card"}-body`}>
        <div className="uk-margin">
          <Textarea
            label="Describe the feedback you are looking for"
            field="description"
            value={state.description}
            handleChange={handleChange}
            minLength={TEXTAREA_MIN}
            maxLength={TEXTAREA_MAX}
            placeholder="What do you want feedback on"
            rows={3}
            required
          />
        </div>
        <div className="uk-margin">
          <CreateSelect
            label="List the topics/areas this feedback is about"
            field="topics"
            placeholder=""
            options={TOPICS}
            value={state.topics}
            handleChange={handleChange}
            minOptions={MIN_REQUEST_TOPICS}
            maxOptions={MAX_REQUEST_TOPICS}
            required
          />
        </div>
        {uploading && <Spinner />}
        {!uploading && (
          <div className="uk-margin">
            <ul data-uk-tab="">
              <li>
                <a href="#upload">Upload</a>
              </li>
              <li>
                <a href="#upload">Record</a>
              </li>
              <li>
                <a href="#google">Google</a>
              </li>
            </ul>
            <ul className="uk-switcher">
              <li>
                <Upload
                  handleChange={handleAttachment}
                  auth={auth}
                  setUploading={setUploading}
                />
              </li>
              <li>
                <Record
                  handleChange={handleAttachment}
                  auth={auth}
                  setUploading={setUploading}
                />
              </li>
              <li>
                <Google
                  handleChange={handleAttachment}
                  auth={auth}
                  setUploading={setUploading}
                />
              </li>
            </ul>
            {state.attachment && (
              <div className="uk-margin-small">
                <View attachment={state.attachment} className="medium" />
              </div>
            )}
            {state.attachment && (
              <div className="uk-margin-small">
                <button
                  type="button"
                  className="uk-button uk-button-small"
                  onClick={handleRemove}
                >
                  remove attachment
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <div className={`uk-${closeModal ? "modal" : "card"}-footer`}>
        <div className="uk-text-right">
          <button
            className="uk-button uk-button-primary"
            type="submit"
            disabled={!savable}
          >
            {request ? "update" : "create"}
          </button>
        </div>
      </div>
    </form>
  );
}

Write.propTypes = {
  auth: PropTypes.object.isRequired,
  request: PropTypes.object,
  createUpdateRequest: PropTypes.func.isRequired,
  closeModal: PropTypes.func,
};

export default Write;
