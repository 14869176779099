import WaveSurfer from "wavesurfer.js";
import MicrophonePlugin from "wavesurfer.js/dist/plugin/wavesurfer.microphone.js";
import "videojs-wavesurfer/dist/css/videojs.wavesurfer.css";
import "videojs-wavesurfer/dist/videojs.wavesurfer.js";
import { MAX_DURATION } from "./constants";

WaveSurfer.microphone = MicrophonePlugin;

const dimensions = {
  width: { min: 640, ideal: 640, max: 1080 },
  height: { min: 480, ideal: 480, max: 720 },
};

const wavesurferOptions = {
  backend: "WebAudio",
  cursorColor: "#1e87f0",
  backgroundColor: "#000",
  waveColor: "#fff",
  progressColor: "#1e87f0",
  displayMilliseconds: false,
  debug: false,
  cursorWidth: 1,
  hideScrollbar: true,
  plugins: [
    WaveSurfer.microphone.create({
      bufferSize: 4096,
      numberOfInputChannels: 1,
      numberOfOutputChannels: 1,
      constraints: {
        audio: true,
        video: false,
      },
    }),
  ],
};

function createOptions({ audio, image, screen, video }) {
  const audioOnly = audio && !image && !video && !screen;
  return {
    controls: true,
    bigPlayButton: false,
    loop: true,
    controlBar: {
      fullscreenToggle: true,
      volumePanel: audio,
    },
    width: 640,
    height: 480,
    fluid: true,
    autoMuteDevice: true,
    plugins: {
      ...(audioOnly && { wavesurfer: wavesurferOptions }),
      record: {
        pip: true,
        debug: false,
        audio,
        video: video ? dimensions : false,
        screen: screen ? dimensions : false,
        image: image ? dimensions : false,
        maxLength: MAX_DURATION,
        frameWidth: 640,
        frameHeight: 480,
        displayMilliseconds: false,
      },
    },
  };
}

export const OPTIONS = {
  image: createOptions({
    image: dimensions,
  }),
  audio: createOptions({
    audio: true,
  }),
  video: createOptions({
    audio: true,
    video: dimensions,
  }),
  screen: createOptions({
    audio: true,
    screen: dimensions,
  }),
};
