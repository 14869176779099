import PropTypes from "prop-types";
import { getLabel } from "../../lib/utils";

function Number({
  label,
  field,
  value,
  handleChange,
  min,
  step,
  max,
  unit,
  required,
  disabled,
}) {
  return (
    <div>
      <label className="uk-form-label">{getLabel(label, required)}</label>
      <div className="uk-form-controls">
        <input
          className="uk-input uk-form-width-small"
          type="number"
          value={value}
          onChange={(e) => handleChange(field, parseInt(e.target.value, 10))}
          step={step || 1}
          min={min}
          max={max}
          required={required}
          disabled={disabled}
        />{" "}
        <div className="uk-text-meta">
          {min}-{max} {unit}
        </div>
      </div>
    </div>
  );
}

Number.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number,
  unit: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Number;
