import { getApp } from "@firebase/app";
import {
  getStripePayments,
  createCheckoutSession,
  getCurrentUserPayments,
} from "@stripe/firestore-stripe-payments";
import { useEffect, useState } from "react";

const app = getApp();
const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "customers",
});

export default function useStripe(auth, request) {
  const [orders, setOrders] = useState([]);

  async function createCheckout(line_item, metadata) {
    const session = await createCheckoutSession(payments, {
      line_items: [line_item],
      mode: "payment",
      allow_promotion_codes: true,
      success_url: window.location.origin + "/success",
      cancel_url: window.location.origin + "/failure",
      metadata,
    }).catch(console.error);
    return session;
  }

  useEffect(() => {
    getCurrentUserPayments(payments).then(setOrders).catch(console.error);
  }, []);

  function filter() {
    return orders.filter(
      (o) => o.uid === auth.uid && o.metadata?.requestId === request.id
    );
  }

  return {
    createCheckout,
    orders: filter(),
  };
}
