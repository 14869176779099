import React from "react";
import PropTypes from "prop-types";
import { attachmentType, linkify } from "../../lib/utils";

function Body({ request, order }) {
  return (
    <div>
      <div
        className="uk-margin-small"
        dangerouslySetInnerHTML={{ __html: linkify(request.description) }}
      />
      <div className="uk-margin-small uk-grid-small" data-uk-grid>
        {request.open && (
          <div>
            <span className="uk-label">open</span>
          </div>
        )}
        {request.attachment && (
          <div>
            <span className="uk-label">
              {attachmentType(request.attachment.mimeType)} attachment
            </span>
          </div>
        )}
        {request.replies && (
          <div>
            <span className="uk-label">{request.replies} replies</span>
          </div>
        )}
        {request.accepted && (
          <div>
            <span className="uk-label">{request.accepted.length} accepted</span>
          </div>
        )}
        {request.rejected && (
          <div>
            <span className="uk-label">{request.rejected.length} rejected</span>
          </div>
        )}
        {order && (
          <>
            <div>
              <span className="uk-label">{order.kind}</span>
            </div>
            <div>
              <span className="uk-label">{order.reviewers} reviewers</span>
            </div>
            <div>
              <span className="uk-label">
                {order.duration} hrs per reviewer
              </span>
            </div>
            <div>
              <span className="uk-label">${order.rate}/hr per reviewer</span>
            </div>
          </>
        )}
        {request.topics &&
          request.topics.map((topic) => (
            <div key={topic.label}>
              <span className="uk-label">{topic.label}</span>
            </div>
          ))}
      </div>
    </div>
  );
}

Body.propTypes = {
  request: PropTypes.object.isRequired,
  order: PropTypes.object,
};

export default Body;
