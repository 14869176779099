/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { OPTIONS } from "../../lib/videojsOptions";
import { setup, reset } from "../../lib/videojs";
import { getLabel, uuid } from "../../lib/utils";
import { uploadFile } from "../../lib/firebase";

function Recorder({ option, setOption, handleChange, auth, setUploading }) {
  const player = useRef(null);
  const node = useRef(null);

  useLayoutEffect(() => {
    async function handleFile(file) {
      setUploading(true);
      const url = await uploadFile(`/requests/${auth.uid}/${uuid()}`, file);
      handleChange({
        name: file.name,
        mimeType: file.type,
        url,
        record: true,
      });
      setOption("");
      setUploading(false);
    }
    if (!player.current)
      player.current = setup(node.current, OPTIONS[option], handleFile);
    return () => {
      if (player.current) reset(player.current);
    };
  }, [option, handleChange, auth, setOption, setUploading]);

  return (
    <div data-vjs-player>
      <video
        id="record-element"
        ref={node}
        className="video-js vjs-default-skin"
        playsInline
      ></video>
    </div>
  );
}

Recorder.propTypes = {
  option: PropTypes.string.isRequired,
  setOption: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  setUploading: PropTypes.func.isRequired,
};

function Record({ handleChange, auth, setUploading, required }) {
  const [option, setOption] = useState("");
  const showImage = option === "image";
  const showVideo = option === "video";
  const showAudio = option === "audio";
  const showScreen = option === "screen";

  return (
    <div>
      <label className="uk-form-label">
        {getLabel("Record audio, video, screen, photo", required)}
      </label>
      <div className="uk-form-controls">
        <div className="uk-margin-small uk-grid-small" data-uk-grid>
          <div>
            <a
              data-uk-tooltip="record audio"
              data-uk-icon="microphone"
              auth={auth}
              onClick={() => setOption("audio")}
              className={`uk-icon-button ${showAudio ? "active" : ""}`}
            />
          </div>
          <div>
            <a
              data-uk-tooltip="take a photo"
              data-uk-icon="camera"
              auth={auth}
              onClick={() => setOption("image")}
              className={`uk-icon-button ${showImage ? "active" : ""}`}
            />
          </div>
          <div>
            <a
              data-uk-tooltip="record video"
              data-uk-icon="video-camera"
              auth={auth}
              onClick={() => setOption("video")}
              className={`uk-icon-button ${showVideo ? "active" : ""}`}
            />
          </div>
          <div>
            <a
              data-uk-tooltip="record screen"
              data-uk-icon="laptop"
              auth={auth}
              onClick={() => setOption("screen")}
              className={`uk-icon-button ${showScreen ? "active" : ""}`}
            />
          </div>
        </div>
        <div className="uk-margin-small" style={{ maxWidth: 640 }}>
          {showAudio && (
            <Recorder
              handleChange={handleChange}
              option="audio"
              auth={auth}
              setUploading={setUploading}
              setOption={setOption}
            />
          )}
          {showImage && (
            <Recorder
              handleChange={handleChange}
              option="image"
              auth={auth}
              setUploading={setUploading}
              setOption={setOption}
            />
          )}
          {showVideo && (
            <Recorder
              handleChange={handleChange}
              option="video"
              auth={auth}
              setUploading={setUploading}
              setOption={setOption}
            />
          )}
          {showScreen && (
            <Recorder
              handleChange={handleChange}
              option="screen"
              auth={auth}
              setUploading={setUploading}
              setOption={setOption}
            />
          )}
        </div>
      </div>
    </div>
  );
}

Record.propTypes = {
  handleChange: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  required: PropTypes.bool,
  setUploading: PropTypes.func.isRequired,
};

export default Record;
