import React, { useState } from "react";
import PropTypes from "prop-types";
import Text from "../Form/Text";
import { checkChanges, notify, validateURL } from "../../lib/utils";

function Reference({ user, updateUser }) {
  const initialize = () => ({
    twitter: user.twitter || "",
    linkedin: user.linkedin || "",
    facebook: user.facebook || "",
    website: user.website || "",
  });
  const [references, setReferences] = useState(initialize);
  const changed = checkChanges(initialize(), references);
  const valid = Object.values(references).some(validateURL);
  const savable = changed && valid;

  function handleChange(label, value) {
    setReferences((state) => ({ ...state, [label]: value }));
  }

  async function handleUpdate(e) {
    e.preventDefault();
    await updateUser(user, references);
    notify("References updated");
  }

  return (
    <form
      className="uk-form-stacked uk-card uk-card-default uk-card-hover"
      onSubmit={handleUpdate}
    >
      <div className="uk-card-body">
        <h5 className="uk-margin uk-text-italic">
          Add references that will help qualify you as an expert in your
          domain(s).
        </h5>

        <div className="uk-margin uk-child-width-expand@m" data-uk-grid>
          <div>
            <Text
              label="LinkedIn profile url"
              field="linkedin"
              value={references.linkedin}
              handleChange={handleChange}
              minLength={2}
              maxLength={200}
              placeholder=""
              type="url"
            />
          </div>
          <div>
            <Text
              label="Twitter profile url"
              field="twitter"
              value={references.twitter}
              handleChange={handleChange}
              minLength={2}
              maxLength={200}
              placeholder=""
              type="url"
            />
          </div>
          <div>
            <Text
              label="Facebook profile url"
              field="facebook"
              value={references.facebook}
              handleChange={handleChange}
              minLength={2}
              maxLength={200}
              placeholder=""
              type="url"
            />
          </div>
          <div>
            <Text
              label="Website or url about you"
              field="website"
              value={references.website}
              handleChange={handleChange}
              minLength={2}
              maxLength={200}
              placeholder=""
              type="url"
            />
          </div>
        </div>
      </div>

      <div className="uk-card-footer uk-text-right">
        <button
          className="uk-button uk-button-primary"
          type="submit"
          disabled={!savable}
        >
          update
        </button>
      </div>
    </form>
  );
}

Reference.propTypes = {
  user: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
};

export default Reference;
