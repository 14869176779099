import React from "react";
import PropTypes from "prop-types";
import RequestWrite from "../components/Request/Write";
import RequestRead from "../components/Request/Read";
import useRequests from "../hooks/useRequests";
import Spinner from "../components/Spinner";

function Home({ auth }) {
  const {
    requests,
    invites,
    loading,
    createRequest,
    updateRequest,
    deleteRequest,
  } = useRequests(auth, { home: true });
  const allRequests = [...requests, ...invites];

  if (loading) return <Spinner full />;

  return (
    <div className="uk-section uk-section-muted">
      <div className="uk-container">
        <div className="uk-margin-medium-bottom">
          <h2 className="uk-margin">Create Feedback Request</h2>
          <div className="uk-margin uk-card uk-card-default uk-card-hover">
            <RequestWrite auth={auth} createUpdateRequest={createRequest} />
          </div>
        </div>

        <div className="uk-margin-medium-bottom">
          <h2 className="uk-margin">Your Feedback Requests </h2>
          <div
            className="uk-margin uk-overflow-auto"
            data-uk-height-viewport="offset-top: true"
          >
            <ul className="uk-subnav uk-subnav-pill" data-uk-switcher="">
              <li>
                <a href="#all">All Requests ({allRequests.length})</a>
              </li>
              <li>
                <a href="#mine">My Requests ({requests.length})</a>
              </li>
              <li>
                <a href="#invitations">My Invitations ({invites.length})</a>
              </li>
            </ul>
            <ul className="uk-switcher">
              <li>
                <ul className="uk-list uk-list-large">
                  {allRequests.map((request) => (
                    <li key={request.id}>
                      <RequestRead
                        auth={auth}
                        request={request}
                        updateRequest={updateRequest}
                        deleteRequest={deleteRequest}
                      />
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <ul className="uk-list uk-list-large">
                  {requests.map((request) => (
                    <li key={request.id}>
                      <RequestRead
                        auth={auth}
                        request={request}
                        updateRequest={updateRequest}
                        deleteRequest={deleteRequest}
                      />
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <ul className="uk-list uk-list-large">
                  {invites.map((request) => (
                    <li key={request.id}>
                      <RequestRead
                        auth={auth}
                        request={request}
                        updateRequest={updateRequest}
                        deleteRequest={deleteRequest}
                      />
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

Home.propTypes = {
  auth: PropTypes.object.isRequired,
};

export default Home;
