import { useState, useEffect } from "react";
import { firestore, getDocs, serverTimestamp } from "../lib/firebase";

const REF = (request) =>
  firestore.collection("requests").doc(request.id).collection("replies");
const LIMIT = 100;

export default function useReplies(auth, request, { all }) {
  const [replies, setReplies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function effect() {
      let ref = REF(request);
      if (!all) ref = ref.where("user.uid", "==", auth.uid);
      const unsubscribe = ref
        .orderBy("created", "desc")
        .limit(LIMIT)
        .onSnapshot((snapshot) => {
          setReplies(getDocs(snapshot));
          setLoading(false);
        });
      return unsubscribe;
    }
    if (request && auth) return effect();
    else setLoading(false);
  }, [request, auth, all]);

  async function createReply(data) {
    await REF(request).add({ ...data, user: auth, created: serverTimestamp() });
  }

  async function updateReply(reply, data) {
    await REF(request)
      .doc(reply.id)
      .update({ ...data, updated: serverTimestamp() });
  }

  async function deleteReply(reply) {
    await REF(request).doc(reply.id).delete();
  }

  function createThreads(replies) {
    const users = [...new Set(replies.map((m) => m.user.uid))];
    const threads = users.reduce((acc, uid) => {
      const thread = replies.filter((m) => m.user.uid === uid);
      acc.push(thread);
      return acc;
    }, []);
    return threads;
  }

  return {
    threads: createThreads(replies),
    loading,
    createReply,
    updateReply,
    deleteReply,
  };
}
