import React from "react";
import PropTypes from "prop-types";
import Spinner from "../components/Spinner";
import Basic from "../components/User/Basic";
import Demographic from "../components/User/Demographic";
import Reviewer from "../components/User/Reviewer";
import Reference from "../components/User/Reference";
import Documents from "../components/User/Documents";

function Settings({ auth, user, updateUser, updateAuth }) {
  if (!user) return <Spinner full />;

  return (
    <div
      className="uk-section uk-section-muted"
      data-uk-height-viewport="offset-top: true"
    >
      <div className="uk-container">
        <div className="uk-margin-medium-bottom">
          <h2 className="uk-margin">Basic Info</h2>
          <div className="uk-margin">
            <Basic auth={auth} updateAuth={updateAuth} />
          </div>
        </div>

        <div className="uk-margin-medium-bottom">
          <h2 className="uk-margin">Demographic Info</h2>
          <div className="uk-margin">
            <Demographic user={user} updateUser={updateUser} />
          </div>
        </div>

        <div className="uk-margin-medium-bottom">
          <h2 className="uk-margin">Reviewer Info (1)</h2>
          <div className="uk-margin">
            <Reviewer user={user} updateUser={updateUser} />
          </div>
        </div>

        <div className="uk-margin-medium-bottom">
          <h2 className="uk-margin">Reviewer Documents (2)</h2>
          <div className="uk-margin">
            <Documents auth={auth} user={user} updateUser={updateUser} />
          </div>
        </div>

        <div className="uk-margin-medium-bottom">
          <h2 className="uk-margin">Reviewer References (3)</h2>
          <div className="uk-margin">
            <Reference user={user} updateUser={updateUser} />
          </div>
        </div>
      </div>
    </div>
  );
}

Settings.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object,
  updateUser: PropTypes.func.isRequired,
  updateAuth: PropTypes.func.isRequired,
};

export default Settings;
