import "video.js/dist/video-js.css";
import videojs from "video.js";
import "webrtc-adapter";
import RecordRTC from "recordrtc";
import "videojs-record/dist/css/videojs.record.css";
import "videojs-record/dist/videojs.record.js";
import { uuid } from "./utils";

export function setup(node, options, handleFile) {
  const player = videojs(node, options, () => {
    videojs.log(
      `Using video.js ${
        videojs.VERSION
      } with videojs-record ${videojs.getPluginVersion(
        "record"
      )} and recordrtc ${RecordRTC.version}`
    );
  });

  player.on("ready", function () {
    player.record().getDevice();
  });

  player.on("deviceReady", () => {
    videojs.log("device ready", player.record().getRecordType());
  });

  player.on("startRecord", () => {
    videojs.log("recording started");
  });

  player.on("stopRecord", function () {
    videojs.log("recording stopped");
  });

  player.on("finishRecord", () => {
    videojs.log("recording finished");
    if (typeof player.recordedData === "string")
      fetch(player.recordedData)
        .then((res) => res.blob())
        .then((blob) => new File([blob], `${uuid()}.png`, { type: blob.type }))
        .then(handleFile)
        .catch(console.error);
    else handleFile(player.recordedData);
  });

  player.on("error", (element, error) => {
    videojs.log(element, error);
  });

  player.on("deviceError", () => {
    videojs.log("device error:", player.deviceErrorCode);
  });

  return player;
}

export function loadOptions(player, options) {
  player.record().loadOptions(options);
}

export function stopDevice(player) {
  player.record().stopDevice();
}

export function dispose(player) {
  player.record().dispose();
}

export function destroy(player) {
  player.record().destroy();
}

export function reset(player) {
  player.record().reset();
}
