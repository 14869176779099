import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import Settings from "./pages/Settings";
import Landing from "./pages/Landing";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import Success from "./pages/Success";
import Failure from "./pages/Failure";
import Navbar from "./components/Navbar";
import Spinner from "./components/Spinner";
import useAuth from "./hooks/useAuth";
import Request from "./pages/Request";

function AuthComponent({ Component, auth, checkAdmin, ...rest }) {
  return (checkAdmin ? auth?.admin : auth) ? (
    <Component auth={auth} {...rest} />
  ) : (
    <Redirect to="/" />
  );
}

AuthComponent.propTypes = {
  Component: PropTypes.func.isRequired,
  auth: PropTypes.object,
  checkAdmin: PropTypes.bool,
};

function App() {
  const { auth, user, loading, signIn, signOut, updateUser, updateAuth } =
    useAuth();

  if (loading) return <Spinner full />;

  return (
    <div>
      <Navbar auth={auth} signIn={signIn} signOut={signOut} />
      <Switch>
        <Route path="/success">
          <AuthComponent auth={auth} Component={Success} />
        </Route>
        <Route path="/failure">
          <AuthComponent auth={auth} Component={Failure} />
        </Route>
        <Route path="/requests/:id">
          <AuthComponent Component={Request} auth={auth} />
        </Route>
        <Route path="/settings">
          <AuthComponent
            Component={Settings}
            auth={auth}
            user={user}
            updateAuth={updateAuth}
            updateUser={updateUser}
          />
        </Route>
        <Route path="/admin">
          <AuthComponent Component={Admin} auth={auth} checkAdmin />
        </Route>
        <Route path="/">
          {auth ? <Home auth={auth} /> : <Landing signIn={signIn} />}
        </Route>
      </Switch>
    </div>
  );
}

export default App;
