import { useState, useEffect } from "react";
import { arrayRemove, arrayUnion, firestore } from "../lib/firebase";

const REF = (request, reply) =>
  firestore
    .collection("requests")
    .doc(request.id)
    .collection("replies")
    .doc(reply.id);

export default function useUpVotes(auth, poll, reply) {
  const [upVotes, setUpVotes] = useState([]);
  const [downVotes, setDownVotes] = useState([]);
  const upVoted = upVotes.some((uid) => auth.uid === uid);
  const downVoted = downVotes.some((uid) => auth.uid === uid);

  useEffect(() => {
    if (reply.upVotes) setUpVotes(reply.upVotes);
    if (reply.downVotes) setDownVotes(reply.downVotes);
  }, [reply]);

  async function upVote() {
    if (downVoted) await downVote();
    await REF(poll, reply).update({
      upVotes: upVoted ? arrayRemove(auth.uid) : arrayUnion(auth.uid),
    });
  }

  async function downVote() {
    if (upVoted) await upVote();
    await REF(poll, reply).update({
      downVotes: downVoted ? arrayRemove(auth.uid) : arrayUnion(auth.uid),
    });
  }
  return {
    upVotes,
    downVotes,
    upVoted,
    downVoted,
    upVote,
    downVote,
  };
}
