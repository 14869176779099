/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import { confirm, linkify, notify } from "../../lib/utils";
import View from "../Attachment/View";
import useUpVotes from "../../hooks/useUpvotes";
import Reply from "./Reply";

function Read({ reply, request, auth, deleteReply, setReply, isAdmin, seek }) {
  const { upVotes, downVotes, upVoted, downVoted, upVote, downVote } =
    useUpVotes(auth, request, reply);
  const isReplier = reply.user.uid === auth.uid;

  async function handleDelete() {
    if (await confirm("Delete reply?")) {
      await deleteReply(reply);
      notify("Reply deleted");
    }
  }

  function handleReply() {
    setReply(reply);
  }

  function handleSeek() {
    seek(reply.time);
  }

  return (
    <div id={reply.id}>
      <div className="uk-margin-small uk-grid-small uk-flex-right" data-uk-grid>
        <div>
          <button
            type="button"
            className="uk-icon-button"
            data-uk-icon="reply"
            data-uk-tooltip="reply"
            onClick={handleReply}
          ></button>
        </div>
        {(isReplier || isAdmin) && (
          <div>
            <button
              type="button"
              className="uk-icon-button"
              data-uk-icon="trash"
              onClick={handleDelete}
              data-uk-tooltip="delete"
            ></button>
          </div>
        )}
      </div>
      {reply.reply && (
        <div className="uk-margin-small">
          <Reply reply={reply.reply} />
        </div>
      )}
      {reply.description && (
        <div
          className="uk-margin-small"
          dangerouslySetInnerHTML={{ __html: linkify(reply.description) }}
        />
      )}
      {reply.attachment && (
        <div className="uk-margin-small">
          <View attachment={reply.attachment} className="medium" />
        </div>
      )}
      {Number.isInteger(reply.time) && (
        <div className="uk-margin-small">
          <a className="uk-link-text" onClick={handleSeek}>
            {reply.time}s
          </a>
        </div>
      )}
      <ul className="uk-margin-small uk-iconnav">
        <li data-uk-tooltip="Upvote" onClick={upVote}>
          <a className={upVoted ? "upvote" : undefined}>
            <span data-uk-icon="arrow-up"></span> {upVotes.length}
          </a>
        </li>
        <li data-uk-tooltip="Downvote" onClick={downVote}>
          <a className={downVoted ? "downvote" : undefined}>
            <span data-uk-icon="arrow-down"></span> {downVotes.length}
          </a>
        </li>
      </ul>
    </div>
  );
}

Read.propTypes = {
  reply: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deleteReply: PropTypes.func.isRequired,
  setReply: PropTypes.func.isRequired,
  seek: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default Read;
