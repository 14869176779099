import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  checkChanges,
  notify,
  validateMultiSelect,
  validateNumber,
  validateString,
} from "../../lib/utils";
import Number from "../Form/Number";
import Textarea from "../Form/Textarea";
import CreateSelect from "../Form/CreateSelect";
import {
  MIN_REVIEWER_HOURS,
  MAX_REVIEWER_HOURS,
  MIN_REVIEWER_RATE,
  MAX_REVIEWER_RATE,
  TOPICS,
  MIN_REVIEWER_TOPICS,
  MAX_REVIEWER_TOPICS,
  TEXTAREA_MAX,
  TEXTAREA_MIN,
  MIN_REQUEST_RATE,
  MAX_REQUEST_RATE,
} from "../../lib/constants";

function Reviewer({ user, updateUser }) {
  const initialize = () => ({
    description: user.description || "",
    topics: user.topics || [],
    rate: user.rate || 0,
    hours: user.hours || 0,
    reviewer: true,
  });
  const [reviewer, setReviewer] = useState(initialize);
  const changed = checkChanges(initialize(), reviewer);
  const valid =
    validateMultiSelect(
      reviewer.topics,
      MIN_REVIEWER_TOPICS,
      MAX_REVIEWER_TOPICS
    ) &&
    validateString(reviewer.description, TEXTAREA_MIN, TEXTAREA_MAX) &&
    validateNumber(reviewer.rate, MIN_REQUEST_RATE, MAX_REQUEST_RATE) &&
    validateNumber(reviewer.hours, MIN_REVIEWER_HOURS, MAX_REVIEWER_HOURS);
  const savable = changed && valid;

  function handleChange(label, value) {
    setReviewer((state) => ({ ...state, [label]: value }));
  }

  async function handleUpdate(e) {
    e.preventDefault();
    await updateUser(user, reviewer);
    notify("Reviewer updated");
  }

  return (
    <form
      className="uk-form-stacked uk-card uk-card-default uk-card-hover"
      onSubmit={handleUpdate}
    >
      <div className="uk-card-body">
        <h5 className="uk-margin uk-text-italic">
          Want to be come a <strong>Paid Reviewer</strong> with EzzyFeedback?
          Fill out your info below based on{" "}
          <strong>your experience, expertise or qualification</strong>. After
          approval, you will be notified whenever there is work for you to
          review and provide feedback.
        </h5>

        <div className="uk-margin">
          <CreateSelect
            label="List the topics/areas you are able to provide feedback on"
            field="topics"
            placeholder=""
            options={TOPICS}
            value={reviewer.topics}
            handleChange={handleChange}
            minOptions={MIN_REVIEWER_TOPICS}
            maxOptions={MAX_REVIEWER_TOPICS}
            required
          />
        </div>

        <div className="uk-margin">
          <Textarea
            label="Describe your expertise, experience or qualification"
            field="description"
            value={reviewer.description}
            handleChange={handleChange}
            minLength={TEXTAREA_MIN}
            maxLength={TEXTAREA_MAX}
            placeholder="What do you want feedback on"
            rows={3}
            required
          />
        </div>

        <div className="uk-margin" data-uk-grid>
          <div>
            <Number
              label="How many hours would you be available to provide feedback per day"
              field="hours"
              value={reviewer.hours}
              handleChange={handleChange}
              min={MIN_REVIEWER_HOURS}
              max={MAX_REVIEWER_HOURS}
              unit="hours"
              required
            />
          </div>
          <div>
            <Number
              label="How much would you want to be paid per hour"
              field="rate"
              value={reviewer.rate}
              handleChange={handleChange}
              min={MIN_REVIEWER_RATE}
              max={MAX_REVIEWER_RATE}
              unit="USD"
              required
            />
          </div>
        </div>
      </div>

      <div className="uk-card-footer uk-text-right">
        <button
          className="uk-button uk-button-primary"
          type="submit"
          disabled={!savable}
        >
          update
        </button>
      </div>
    </form>
  );
}

Reviewer.propTypes = {
  user: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
};

export default Reviewer;
