import React from "react";
import PropTypes from "prop-types";
import useGoogle from "../../hooks/useGoogle";
import usePicker from "../../hooks/usePicker";
import { getLabel, uuid } from "../../lib/utils";
import { uploadFile } from "../../lib/firebase";
import Spinner from "../Spinner";
import { exportFile } from "../../lib/attachment";

function Google({ auth, handleChange, setUploading, required }) {
  const { accessToken, authorize, revoke, loading } = useGoogle(auth);

  const { open } = usePicker(accessToken, async ([doc]) => {
    if (doc) {
      setUploading(true);
      const file = await exportFile(doc.id, doc.name, doc.type);
      if (file) {
        const url = await uploadFile(`/requests/${auth.uid}/${uuid()}`, file);
        handleChange({
          name: file.name,
          mimeType: file.type,
          url,
          google: true,
          metadata: doc,
        });
      }
      setUploading(false);
    }
  });

  if (loading) return <Spinner />;

  return (
    <div>
      <label className="uk-form-label">
        {getLabel("Import from Google", required)}
      </label>
      <div className="uk-form-controls">
        <div className="uk-grid-small" data-uk-grid>
          {accessToken && (
            <>
              <div>
                <button
                  type="button"
                  className="uk-button uk-button-default"
                  onClick={open}
                >
                  import files
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="uk-button uk-button-text"
                  onClick={revoke}
                >
                  revoke google
                </button>
              </div>
            </>
          )}
          {!accessToken && (
            <div>
              <button
                type="button"
                className="uk-button uk-button-primary"
                onClick={authorize}
              >
                authorize google
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Google.propTypes = {
  handleChange: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  required: PropTypes.bool,
  setUploading: PropTypes.func.isRequired,
};

export default Google;
