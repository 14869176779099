import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { notify } from "../lib/utils";
import Center from "../components/Center";

function Failure() {
  const { searchParams } = new URL(window.location.href);
  const sessionId = searchParams.get("session_id");
  const history = useHistory();

  useEffect(() => {
    function effect() {
      history.push("/failure");
      notify("Order failed!");
    }
    if (sessionId) effect();
  }, [sessionId, history]);

  return (
    <div className="uk-section uk-section-muted">
      <div className="uk-container">
        <Center>
          <h2 className="uk-margin uk-text-error">Purchase failed! 😞</h2>
          <p className="uk-margin"> Try again </p>
          <div className="uk-margin">
            <Link to="/" className="uk-button uk-button-default">
              Back to home
            </Link>
          </div>
        </Center>
      </div>
    </div>
  );
}

export default Failure;
