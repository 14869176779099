import { useEffect, useState } from "react";
import { firestore, getDoc, serverTimestamp } from "../lib/firebase";

const REF = (request, user) =>
  firestore
    .collection("requests")
    .doc(request.id)
    .collection("reviews")
    .doc(user.uid);

export default function useReview(auth, request, user) {
  const [review, setReview] = useState(null);

  useEffect(() => {
    function effect() {
      const unsubscribe = REF(request, user).onSnapshot((snapshot) =>
        setReview(getDoc(snapshot))
      );
      return unsubscribe;
    }
    if (request && user && auth) return effect();
  }, [request, user, auth]);

  async function updateReview(data) {
    await REF(request, user).set(
      { ...data, updated: serverTimestamp() },
      { merge: true }
    );
  }

  return { review, updateReview };
}
