import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  getLabel,
  notify,
  uuid,
  validateString,
  validateURL,
} from "../../lib/utils";
import { uploadFile } from "../../lib/firebase";
import Text from "../Form/Text";
import { TEXT_MIN, TEXT_MAX } from "../../lib/constants";

function Basic({ auth, updateAuth }) {
  const [displayName, setName] = useState(auth.name);
  const [photoURL, setPhotoURL] = useState(auth.photo);
  const [changed, setChanged] = useState(false);
  const savable =
    changed &&
    validateString(displayName, TEXT_MIN, TEXT_MAX) &&
    validateURL(photoURL);

  function handleName(value) {
    setName(value);
    setChanged(true);
  }

  async function handlePhoto(e) {
    const file = e.target.files[0];
    if (file) {
      const path = `/profiles/${auth.uid}/${uuid()}`;
      const url = await uploadFile(path, file);
      setPhotoURL(url);
      setChanged(true);
    }
  }

  async function handleSave(e) {
    e.preventDefault();
    await updateAuth({ photoURL, displayName });
    setChanged(false);
    notify("Profile updated");
  }

  return (
    <form
      className="uk-form-stacked uk-card uk-card-default uk-card-hover"
      onSubmit={handleSave}
    >
      <div className="uk-card-body">
        <div className="uk-child-width-expand@m" data-uk-grid>
          <div>
            <Text
              label="Name"
              field="displayName"
              value={displayName}
              handleChange={(field, value) => handleName(value)}
              minLength={TEXT_MIN}
              maxLength={TEXT_MAX}
              placeholder=""
              required
            />
          </div>
          <div>
            <Text
              label="Email"
              field="displayName"
              value={auth.email}
              handleChange={(field, value) => {}}
              minLength={TEXT_MIN}
              maxLength={TEXT_MAX}
              placeholder=""
              required
              disabled
            />
          </div>
          <div>
            <label className="uk-form-label">{getLabel("Photo", true)}</label>
            <div className="uk-form-controls">
              <div className="uk-margin-small">
                <img src={photoURL} alt="avatar" className="avatar" />
              </div>
              <div className="uk-margin-small" data-uk-form-custom="">
                <input type="file" onChange={handlePhoto} accept="image/*" />
                <button className="uk-button uk-button-small" type="button">
                  update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="uk-card-footer uk-text-right">
        <button
          className="uk-button uk-button-primary"
          type="submit"
          disabled={!savable}
        >
          update
        </button>
      </div>
    </form>
  );
}

Basic.propTypes = {
  auth: PropTypes.object.isRequired,
  updateAuth: PropTypes.func.isRequired,
};

export default Basic;
