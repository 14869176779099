import { useState, useEffect } from "react";
import { firestore, getDocs, serverTimestamp } from "../lib/firebase";

const LIMIT = 100;
const REF = firestore.collection("orders");

export default function useOrders(auth, { admin, request }) {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function effect() {
      let query;
      if (admin) query = REF.orderBy("request");
      if (request) query = REF.where("request", "==", request.id);
      if (query) {
        const unsubscribe = query
          .orderBy("created", "desc")
          .limit(LIMIT)
          .onSnapshot((snapshot) => {
            setOrders(getDocs(snapshot));
            setLoading(false);
          });
        return unsubscribe;
      }
    }
    if (auth) return effect();
    else setLoading(false);
  }, [auth, admin, request]);

  function createOrder(data) {
    REF.add({
      ...data,
      type: "request",
      user: auth,
      created: serverTimestamp(),
    });
  }

  return { orders, createOrder, loading };
}
