/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import logo from "./logo.png";

function Navbar({ auth, signIn, signOut }) {
  return (
    <div className="uk-section uk-section-muted uk-padding-remove-vertical">
      <div className="uk-container">
        <nav
          className="uk-navbar-container"
          data-uk-navbar="offset: 0; boundary-align: true; align: right; mode: click"
        >
          <div className="uk-navbar-left">
            <Link to="/" className="uk-navbar-item">
              <img src={logo} alt="logo" width="80" />
            </Link>
          </div>

          <div className="uk-navbar-right">
            {!auth && (
              <div className="uk-navbar-item">
                <button
                  type="button"
                  onClick={signIn}
                  className="uk-button uk-button-default"
                >
                  get feedback
                </button>
              </div>
            )}
            {auth && (
              <ul className="uk-navbar-nav">
                <li>
                  <a>
                    <img
                      src={auth.photo}
                      alt={auth.name}
                      width="40"
                      height="40"
                      className="uk-border-circle"
                    />
                  </a>
                  <div className="uk-navbar-dropdown uk-width-small">
                    <ul className="uk-nav uk-navbar-dropdown-nav uk-text-uppercase">
                      {auth.admin && (
                        <li>
                          <Link to="/admin">admin</Link>
                        </li>
                      )}
                      <li>
                        <Link to="/settings">settings</Link>
                      </li>
                      <li>
                        <a onClick={signOut}>sign out</a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </nav>
      </div>
    </div>
  );
}

Navbar.propTypes = {
  auth: PropTypes.object,
  signIn: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default Navbar;
