import { useRef, useState } from "react";
import { MAX_DURATION } from "../lib/constants";

export default function useAudioVideo() {
  const [state, setState] = useState({
    currentTime: 0,
    duration: MAX_DURATION,
  });
  const ref = useRef(null);

  function seek(currentTime) {
    ref.current.currentTime = currentTime;
  }

  function timeUpdate(e) {
    const { currentTime, duration } = ref.current;
    setState({ currentTime, duration });
  }

  return [
    ref,
    seek,
    timeUpdate,
    Math.round(state.currentTime),
    Math.round(state.duration),
  ];
}
