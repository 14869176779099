import { useEffect, useState } from "react";
import {
  auth as Auth,
  firestore,
  getDoc,
  google,
  serverTimestamp,
} from "../lib/firebase";
import { selectAuth } from "../lib/utils";

const REF = firestore.collection("users");

async function createUpdateUser(auth) {
  const snapshot = await REF.doc(auth.uid).get();
  await REF.doc(auth.uid).set(
    {
      ...auth,
      [!snapshot.exists ? "created" : "updated"]: serverTimestamp(),
    },
    { merge: true }
  );
}

function useAuth() {
  const [auth, setAuth] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function effect() {
      const unsubscribe = Auth.onAuthStateChanged(async (user) => {
        if (user) {
          const token = user ? await user.getIdTokenResult() : false;
          setAuth(selectAuth(user, token.claims.admin));
        } else {
          setAuth(null);
        }
        setLoading(false);
      });
      return unsubscribe;
    }
    return effect();
  }, []);

  useEffect(() => {
    if (auth) {
      const unsubscribe = REF.doc(auth.uid).onSnapshot((snapshot) =>
        setUser(getDoc(snapshot))
      );
      return unsubscribe;
    } else {
      setUser(null);
    }
  }, [auth]);

  async function signIn(callback) {
    const { user } = await Auth.signInWithPopup(google).catch(console.error);
    if (user) {
      await createUpdateUser(selectAuth(user));
      if (typeof callback === "function") await callback(selectAuth(user));
    }
  }

  async function signOut() {
    await Auth.signOut().catch(console.error);
  }

  async function updateAuth({ displayName, photoURL }) {
    await Auth.currentUser.updateProfile({ displayName, photoURL });
    await Auth.currentUser.reload();
  }

  async function updateUser(user, data) {
    await REF.doc(user.id).update({ ...data, updated: serverTimestamp() });
  }

  return { auth, user, loading, signIn, signOut, updateAuth, updateUser };
}

export default useAuth;
