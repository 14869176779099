import React from "react";
import PropTypes from "prop-types";

function Header({ user }) {
  return (
    <div className="uk-grid-small uk-flex-middle" data-uk-grid>
      <div>
        <img
          className="uk-border-circle"
          width="40"
          height="40"
          src={user.photo}
          alt={user.name}
        />
      </div>
      <div className="uk-text-bold">{user.name}</div>
    </div>
  );
}

Header.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Header;
