import { DOCUMENT_TYPES, PDF_MIMETYPE, CONVERT_KEY } from "./constants";

function getFile(binary, fileName, mimeType) {
  const len = binary.length;
  const array = new Uint8Array(len);
  for (let i = 0; i < len; i += 1) array[i] = binary.charCodeAt(i);
  return new File([array], fileName, { type: mimeType });
}

const URLConvertCheck = (from, to) =>
  `https://v2.convertapi.com/info/canconvert/${from}/to/${to}`;

const URLConvertFile = (from, to) =>
  `https://v2.convertapi.com/convert/${from}/to/${to}?Secret=${CONVERT_KEY}`;

async function convertFile(file, to, mimeType) {
  const from = file.name.split(".").pop();
  let res = await fetch(URLConvertCheck(from, to));
  if (res.ok) {
    const formData = new FormData();
    formData.append("File", file);
    res = await fetch(URLConvertFile(from, to), {
      method: "POST",
      credentials: "omit",
      body: formData,
    });
    if (res.ok) {
      const { Files = [] } = await res.json();
      const [File] = Files;
      if (File)
        return getFile(window.atob(File.FileData), File.FileName, mimeType);
    }
  }
}

export async function exportFile(id, name, type) {
  const res = await (type === "document"
    ? window.gapi.client.drive.files.export({
        fileId: id,
        mimeType: PDF_MIMETYPE,
      })
    : window.gapi.client.drive.files.get({ fileId: id, alt: "media" }));
  if (res.status < 400) {
    let file = getFile(res.body, name, res.headers["Content-Type"]);
    if (type === "file") file = await convertFile(file, "pdf", PDF_MIMETYPE);
    return file;
  }
}

export async function selectFile(file) {
  if (DOCUMENT_TYPES.includes(file.type) && file.type !== PDF_MIMETYPE)
    file = await convertFile(file, "pdf", PDF_MIMETYPE);
  return file;
}
