/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import PropTypes from "prop-types";
import { DOCUMENT_TYPES } from "../../lib/constants";
import { getLabel, uuid } from "../../lib/utils";
import { uploadFile } from "../../lib/firebase";
import { selectFile } from "../../lib/attachment";

function Upload({ auth, handleChange, required, setUploading }) {
  async function handleUpload(e) {
    let file = e.target.files[0];
    if (file) {
      setUploading(true);
      file = await selectFile(file);
      if (file) {
        const url = await uploadFile(`/requests/${auth.uid}/${uuid()}`, file);
        handleChange({
          name: file.name,
          mimeType: file.type,
          url,
          upload: true,
        });
      }
      setUploading(false);
    }
  }

  return (
    <div>
      <label className="uk-form-label">
        {getLabel("Upload document, photo, audio, video", required)}
      </label>
      <div className="uk-form-controls">
        <div className="uk-grid-small" data-uk-grid>
          <div data-uk-form-custom="">
            <input
              onChange={handleUpload}
              type="file"
              accept={DOCUMENT_TYPES}
              capture
              data-uk-tooltip="select document"
            />
            <a className="uk-icon-button" data-uk-icon="file-text"></a>
          </div>
          <div data-uk-form-custom="">
            <input
              onChange={handleUpload}
              type="file"
              accept="image/*"
              capture
              data-uk-tooltip="select image"
            />
            <a className="uk-icon-button" data-uk-icon="camera"></a>
          </div>
          <div data-uk-form-custom="">
            <input
              onChange={handleUpload}
              type="file"
              accept="audio/*"
              capture
              data-uk-tooltip="select audio"
            />
            <a className="uk-icon-button" data-uk-icon="microphone"></a>
          </div>
          <div data-uk-form-custom="">
            <input
              onChange={handleUpload}
              type="file"
              accept="video/*"
              capture
              data-uk-tooltip="select video"
            />
            <a className="uk-icon-button" data-uk-icon="video-camera"></a>
          </div>
        </div>
      </div>
    </div>
  );
}

Upload.propTypes = {
  auth: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  setUploading: PropTypes.func.isRequired,
};

export default Upload;
