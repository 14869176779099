import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/analytics";
import { IS_PRODUCTION } from "./constants";
const firebaseDev = require("./firebase.dev.json");
const firebaseProd = require("./firebase.prod.json");

firebase.initializeApp(IS_PRODUCTION ? firebaseProd : firebaseDev);
firebase.analytics();

export const auth = firebase.auth();
export const storage = firebase.storage();
export const firestore = firebase.firestore();
export const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
export const arrayRemove = firebase.firestore.FieldValue.arrayRemove;
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export const increment = firebase.firestore.FieldValue.increment;
export const google = new firebase.auth.GoogleAuthProvider();

const unpack = (docSnapshot) => ({
  id: docSnapshot.id,
  ...docSnapshot.data(),
});

export function getDoc(snapshot) {
  if (snapshot.exists) return unpack(snapshot);
  else return null;
}

export function getDocs(snapshot) {
  const collection = [];
  snapshot.forEach((doc) => {
    collection.push(unpack(doc));
  });
  return collection;
}

export async function uploadFile(path, file) {
  const ref = storage.ref(path);
  await ref.put(file);
  const url = await ref.getDownloadURL();
  return url;
}

export async function deleteFile(url) {
  const ref = storage.refFromURL(url);
  await ref.delete();
}

export default firebase;
