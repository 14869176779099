import { IS_PRODUCTION } from "../lib/constants";

const GOOGLE = {
  API_KEY: IS_PRODUCTION
    ? "AIzaSyDe-ogiDm-RJJkbvunf0T_INpw2Gx2UpG0"
    : "AIzaSyB3FiYqEbS2VvTzB2q2ubHjQvHfJOp8If0",
  ADOBE_CLIENT_ID: IS_PRODUCTION
    ? "756408135600-c3h7g4modutg08bscvl8uvfkb4aldlkn.apps.googleusercontent.com"
    : "161273173842-id2akug8e94rg2dqcdgcbu97s8sjekhu.apps.googleusercontent.com",
  APP_ID: IS_PRODUCTION ? "756408135600" : "161273173842",
  SCOPES: "https://www.googleapis.com/auth/drive.file",
  DISCOVERY_DOCS: [
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
  ],
};

export default GOOGLE;
